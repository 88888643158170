.progressbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  &_container {
    width: 100%;
    height: 7px;
    background-color: var(--grey-30);
    border-radius: 4px;
  }

  &_line {
    background: linear-gradient(30deg, #00998c, #36505b);
    height: 100%;
    border-radius: 4px;
  }

  &_text {
    font-size: 1.2rem;
    color: var(--teal-0);
    font-weight: 500;
  }
}
