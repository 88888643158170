.button {
  background: linear-gradient(30deg, #00998c, #36505b);
  padding: 9px 15px;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  line-height: 1.3;
  border: none;
  transition: all 400ms ease-in;
  white-space: nowrap;
  justify-content: center;

  &.default {
    background: linear-gradient(30deg, #00998c, #36505b);

    &:hover {
      background: linear-gradient(30deg, #36505b, #00998c);
    }

    &.outline {
      background: transparent;
      border: 2px solid #36505b;
      color: #36505b;
      transition: 0.3s all;
      padding: 7px 15px;

      &:hover {
        background: #36505b;
        color: #fff;
      }
    }
  }

  &.primary {
    background: transparent;
    color: #212121;
  }

  &.secondary {
    background: #dddddd;
    color: #212121;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.warning {
    background: linear-gradient(to bottom, #fca659, #e85e40);
    color: #fff;

    &:hover {
      background: linear-gradient(to top, #fca659, #e85e40);
    }

    &.outline {
      background: #fff;
      border: 2px solid #e85e40;
      color: #e85e40;
      transition: 0.3s all;
      padding: 7px 15px;

      &:hover {
        background-color: #e85e40;
        color: #fff;
      }
    }
  }
}
