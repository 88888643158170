.layerlist {
  width: 100%;
  overflow-y: auto;
  background: var(--grey-10);
  border-radius: 4px;
  position: relative;

  @media screen and (max-width: 1024px) {
    &.expandMore {
      height: 100%;
    }

    &.expandLess {
      height: 50px;
      overflow: hidden;
    }
  }
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #55555533;
  z-index: 9;
  transition:
    visibility 0.5s,
    opacity 0.25s linear;
}

.layer {
  &_item {
    padding: 1.5rem 2rem;
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
    opacity: 0.5;

    svg {
      margin: 0.25rem 0;
    }

    &_group {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &.active {
      opacity: 1;
      background-color: var(--grey-20);
    }

    &_title {
      font-size: 1.275rem;
      font-weight: 600;
      line-height: 2rem;
      width: 100%;

      @media screen and (max-width: 1024px) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .layer_drop {
          padding: 0 1rem;
        }
      }
    }

    &_description {
      font-size: 1.275rem;
    }
  }

  &_bullet {
    font-size: 1.6rem;
    font-weight: 400;
  }

  &_border {
    padding: 0 2rem;
  }

  &_line {
    border: 0.2px solid rgba(200, 200, 200, 0.5);
  }

  &_childs {
    list-style: none;
    width: 100%;
  }

  &_child {
    padding: 1.5rem 4rem;
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;

    svg {
      margin: 0.35rem 0;
    }
  }

  &_active {
    background: linear-gradient(30deg, #00998c, #36505b);
    color: var(--white);
    width: 100%;
  }
}
