.coupon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  &_tiny {
    display: flex;
    width: 320px;
    justify-content: space-between;
    align-items: center;
    background-color: #a8f4a4;
    color: var(--foreground);
    padding: 0.5rem;
    border-radius: 3px;
    position: relative;

    span {
      font-size: 1.25rem;
      font-weight: 500;
    }

    svg {
      position: absolute;
      right: -15px;
      background: #ff3030;
      padding: 0.5rem;
      height: 2.75rem;
      width: 2rem;
      cursor: pointer;
      border-radius: 3px;
    }
  }

  &_logo {
    width: 50px;
    height: 50px;

    svg {
      height: 100%;
      width: 100%;
      object-fit: contain;
      fill: #00998c;
    }
  }

  &_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &_code {
    font-weight: 500;
    font-size: 2rem;
  }

  &_off {
    width: 100px;
    font-weight: 700;
    font-size: 3rem;
    text-align: right;
  }

  &_valid {
    font-size: 1.25rem;
  }

  &_detail {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }

  &_label {
    font-size: 1.5rem;
  }

  &_buttons {
    display: flex;
    gap: 1rem;
  }
}
