.checkout {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  min-height: calc(100vh - 125px);
  position: relative;

  &_container {
    display: flex;
    width: 100%;
    gap: 2rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &_sidebar {
    min-width: 500px;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-width: 320px;
    }

    .payout {
      min-width: 500px;
      position: sticky;
      top: 0px;

      @media screen and (max-width: 1024px) {
        width: 100%;
        min-width: 320px;
      }
    }

    .pay {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      padding: 2rem 1rem;
    }
  }

  &_title {
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    gap: 1rem;
    align-items: center;

    svg {
      fill: #00998c;
    }
  }

  &_label {
    font-size: 2rem;
    font-weight: 600;
  }

  .duration {
    font-size: 1.25rem;
    font-weight: 500;
    max-width: 400px;
  }

  &_grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 2rem 1rem 1rem;
  }

  &_cart {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;

    span {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  &_total {
    font-size: 2rem !important;
  }

  &_button {
    align-self: flex-end;
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
  }

  &_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &_shipping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  &_provider {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &_currency {
    font-weight: 600;
    font-size: 1.5rem;
    flex-shrink: 0;
  }

  &_project {
    padding: 2rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
  }

  &_product {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 2rem;

  &_button {
    margin-top: auto;
    align-self: flex-end;
  }
}

.payment {
  width: 100%;
  padding: 1rem;
}

.border {
  width: 100%;
  border: 1px solid #9f9f9f47;
  padding: 1.2rem 1rem;
  border-radius: 5px;
}

.col {
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.35rem;
  }
}

.row_card {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #90909030;
  padding: 1rem 2rem;
}

.radio_card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  span {
    font-weight: 600;
  }

  .row {
    display: flex;
    gap: 2rem;
  }

  .numbers {
    font-size: 2rem;
  }

  .alignEnd {
    align-self: flex-end;
    width: 200px;
    display: flex;
    justify-content: space-between;
  }
}

.error {
  color: #fff;
  background-color: rgb(255, 101, 101);
  padding: 1rem 2rem;
}

.delete_card {
  padding: 1rem;
  margin-left: 2rem;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  cursor: pointer;
  border: 1px solid rgb(255, 101, 101);
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    height: 20px;
    width: 20px;
    fill: rgb(255, 101, 101);
  }
}

.buttons {
  margin-top: 2rem;
  display: flex;
  gap: 1.5rem;
}
