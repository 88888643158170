.fund {
  width: 100%;
  position: relative;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%);
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden;
  gap: 2rem;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .col {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    flex-shrink: 0;
    order: 2;
    padding: 1.25rem 0;
  }

  &_price,
  &_percentage,
  &_title {
    width: 100%;
    text-align: left;
    color: #00998c;
    font-weight: 600;
  }

  &_price {
    margin-top: auto;
    color: var(--foreground);
    font-weight: 600;
    font-size: 2rem;
  }

  &_title {
    color: var(--foreground);
    font-size: 2rem;
  }

  &_type {
    color: var(--grey-50);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.9rem;
    width: 100%;
  }

  &_title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--foreground);
  }

  h2 {
    margin-bottom: 0;
    padding-right: 1rem;
  }

  &_line {
    margin-top: auto;
  }

  &_image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    order: 1;

    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: auto;

    .details {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      &_type {
        color: var(--grey-50);
        font-weight: 500;
        font-size: 1.2rem;
        width: 100%;
      }

      &_title {
        font-weight: 700;
        font-size: 2rem;
      }
    }
  }

  &_buttons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #85858585;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &_progress {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: 1rem;
  }

  &_modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5rem 2rem;
    gap: 3rem;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &_container {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    width: 100%;
    gap: 3rem;
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    flex-direction: column;
    gap: 5rem;
  }

  &_modalImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    border-radius: 10px;

    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &_description {
    flex: 3;
  }

  .modal_buttons {
    margin-top: auto;
    align-self: flex-end;
  }

  &_ecommerce {
    display: flex;
    gap: 1rem;
    margin-top: auto;
    width: 100%;

    & > * {
      font-size: 1.5rem;
      flex: 1;
      width: 100%;
    }
  }
}
