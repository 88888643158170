.menu {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_item {
    display: flex;
    align-items: center;
  }

  &_carts {
    color: #00998c;
    font-size: 3rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
  &_logout {
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    display: flex;
    align-items: center;
    gap: 1rem;

    .logout_icon {
      color: #00998c;
    }
  }

  &_cart {
    color: #00998c;
    font-size: 2rem;
    margin-top: 10px;
  }

  .cart {
    position: relative;
    padding: 0 1rem;
  }

  .cart_number {
    position: absolute;
    display: block;
    flex-shrink: 0;
    top: 0;
    right: 0px;
    padding: 0.125rem 0.75rem;
    border-radius: 50%;
    font-weight: 700;
    background-color: red;
    color: #fff;
    font-size: 1.15rem;
  }

  &_left {
    margin-right: auto;
    display: flex;
    align-items: center;

    .breadcrumb_item {
      display: block;
      text-decoration: none;
      color: #555;
      font-size: 1.4rem;
      line-height: 1.5rem;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }

    .breadcrumb_last {
      text-decoration: none;
      color: #444;
      font-size: 1.4rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-transform: capitalize;
      text-align: center;
      cursor: default;
      font-weight: 500;
      margin-top: 2.5px;
    }
  }

  &_right {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.4rem;
    gap: 2rem;

    button {
      font-size: 1.4rem;
    }

    .menu_user {
      font-weight: 400;
      font-size: 1.4rem;
      padding: 0;
    }

    .view_toggler {
      font-size: inherit;
      gap: 1rem;
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
