.render_string {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.6rem;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 90%;
  }
}
