:root {
  --black: #000;
  --white: #fff;
  --foreground: #4c4c4c;

  --grey-0: #ffffff;
  --grey-5: #f6f5f7;
  --grey-6: #efefef;
  --grey-7: #f3f0f5;
  --grey-10: #f0ebf2;
  --grey-20: #e0d8e5;
  --grey-30: #c6bacc;
  --grey-40: #aa9ab2;
  --grey-50: #aaaaaa;
  --grey-60: #786980;
  --grey-70: #5f5166;
  --grey-80: #463a4d;
  --grey-90: #2e2533;
  --grey-95: #201923;
  --grey-100: #17121a;

  --teal-0: #00998c;
  --teal-10: #00a299;
  --teal-20: #00a29933;
  --teal-50: #00a29980;

  --slide-toggle-thumb-color: var(--white);
  --slide-toggle-track-color: var(--grey-50);
  --slide-toggle-ripple-color: var(--grey-100);
  --slide-toggle-track-active-color: var(--teal-0);
  --slide-toggle-focus-ripple-color: var(--teal-10);
  --slide-toggle-thumb-active-color: var(--teal-0);
}
