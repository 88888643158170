.menu_profile {
  padding: 1rem;
  background-color: var(--grey-30);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
  padding: 0;
}
