.card {
  box-shadow:
    0 4px 4px -1px rgb(0 0 0 / 0.1),
    0 2px 3px 1px rgb(0 0 0 / 0.1);

  border-radius: 10px;
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  &.row {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    &.row {
      flex-direction: row;
    }
  }

  &.col {
    flex-direction: column;
  }
}
