.book {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;

  &_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &_selector {
    flex: 1;
    position: relative;
    overflow-y: auto;
  }

  &_content {
    flex: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  &_header {
    width: 100%;
  }

  &_progress {
    width: 320px;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  &_section {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 1rem;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    &_description {
      font-size: 1.6rem;
    }
  }

  &_footer {
    width: 100%;
    margin-top: auto;
    background: linear-gradient(30deg, #00998c, #36505b);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    border-radius: 1rem 1rem 0 0;
  }

  &_error {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 125px);
    align-items: center;
    justify-content: center;

    .error_content {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-direction: column;

      h1 {
        margin-bottom: 2rem;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 3rem;
      }
    }
  }
}
