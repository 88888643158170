// user quill styles
.resource-styles {
  blockquote {
    font-style: italic;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #222222;
    margin-top: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  ul {
    list-style: none;
    margin-bottom: 1.5rem;
    margin-left: 5rem;
    margin-right: 3rem;

    li {
      margin-bottom: 0.6rem;
      &:not(.ql-indent-1):not(.ql-indent-2)::before {
        content: '';
        -webkit-mask: url(../../images/icons/circle-arrow-right-solid.svg) no-repeat 50% 50%;
        mask: url(../../images/icons/circle-arrow-right-solid.svg) no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        color: #222222;
        background-color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: -0.125em;
      }
    }
    .ql-indent-1 {
      margin-left: 3rem;
      &::before {
        content: '•';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
    }
    .ql-indent-2 {
      margin-left: 6rem;
      &::before {
        content: '›';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: item;
    margin-bottom: 1.5rem;
    margin-left: 5rem;
    margin-right: 3rem;

    li {
      margin-bottom: 0.6rem;
      counter-increment: item;
      &:not(.ql-indent-1):not(.ql-indent-2)::marker {
        content: counter(item, decimal-leading-zero) '.  ';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
      }
    }
    .ql-indent-1:first-of-type {
      counter-reset: subItem;
    }
    .ql-indent-1 {
      counter-increment: subItem;
      margin-left: 2.5rem;
      &::marker {
        content: counter(subItem, lower-alpha) '.  ';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
    }
  }
}

// admin quill styles
.ql-container {
  h1,
  h2,
  h3 {
    margin-bottom: 1.5rem !important;
  }
  p {
    margin-bottom: 1rem !important;
  }
  blockquote {
    font-style: italic;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #222222;
    margin-top: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  ul {
    list-style: none;
    margin-bottom: 1.5rem;
    margin-left: 5rem;
    margin-right: 3rem;

    li {
      margin-bottom: 0.6rem;
      &:not(.ql-indent-1):not(.ql-indent-2)::before {
        content: '';
        -webkit-mask: url(../../images/icons/circle-arrow-right-solid.svg) no-repeat 50% 50%;
        mask: url(../../images/icons/circle-arrow-right-solid.svg) no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        color: #222222;
        background-color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: -0.125em;
      }
    }
    .ql-indent-1 {
      margin-left: 3rem !important;
      padding-left: 1.5rem !important;
      &::before {
        content: '•';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem !important;
        margin-left: -3.1rem !important;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
    }
    .ql-indent-2 {
      margin-left: 5rem !important;
      padding-left: 1.5rem !important;
      &::before {
        content: '›';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem !important;
        margin-left: -3.1rem !important;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: item !important;
    margin-bottom: 1.5rem !important;
    margin-left: 2rem !important;
    margin-right: 3rem !important;

    li {
      margin-bottom: 0.6rem;
      padding-left: 0rem !important;
      counter-increment: item !important;
      &:not(.ql-indent-1):not(.ql-indent-2)::marker {
        content: counter(item, decimal-leading-zero) '.  ';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
      }
      &::before {
        display: none;
      }
    }
    .ql-indent-1:first-of-type {
      counter-reset: subItem;
    }
    .ql-indent-1 {
      counter-increment: subItem !important;
      margin-left: 2.5rem;
      padding-left: 0 !important;
      &::marker {
        content: counter(subItem, lower-alpha) '.  ';
        color: #00998c;
        font-weight: bold;
        display: inline-block;
        margin-right: 1.5rem;
        margin-left: -3.1rem;
        width: 1em;
        height: 1em;
        vertical-align: 0;
      }
      &::before {
        display: none;
      }
    }
  }
}
