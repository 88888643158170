@import url(./../../Styles/mixins/mixins.scss);

.modal {
  position: fixed;
  z-index: 99;
  overflow: hidden;
  background: rgba(84, 84, 84, 0.8);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .workbookbtn-modal {
    max-width: 50px;
  }

  &_container {
    position: relative;
    //width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 3rem;
    border-radius: 10px;
    margin: 20px;

    &.transparent {
      background-color: #fff;
      padding: 0.5rem 0;
      border-radius: 5px;
    }

    &.default {
      max-height: 90vh;
      width: 95vw;
      min-width: 350px;

      @media only screen and (min-width: 769px) {
        max-width: 1000px;
      }
    }

    &.small {
      height: 500px;
      width: 700px;

      @media only screen and (min-width: 410px) {
        height: 560px;
      }
    }
    &.tiny {
      height: 360px;
      width: 500px;
    }

    &.smallM {
      height: 560px;
      width: 900px;
    }

    &.medium {
      height: 760px;
      width: 1100px;
    }

    @media screen and (max-width: 680px) {
      padding: 30px 15px 15px;
      max-width: unset;
    }
  }

  &_close {
    position: absolute;
    top: 4px;
    right: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid;
  }

  &_prev {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(102, 102, 102, 0.2);
  }
}
