.workbookitem {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.content {
    gap: 1rem;
  }

  &_description {
    font-size: 1.6rem;
  }

  &.assessment {
    gap: 2rem;
  }
}

.assessment_order {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  .question {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .bullet {
    font-size: 1rem;
    margin-top: 1px;
  }

  .title {
    font-size: 1.6rem;

    &.errorQuestion {
      color: red;
    }
  }
}
