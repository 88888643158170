.languageselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    fill: #00998c !important;
    font-size: 2rem;
  }

  button {
    color: #212121 !important;
    text-transform: capitalize;
    font-size: 1.4rem !important;
    display: flex;
    align-items: center;
  }
}
