.loading {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 360px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
}
