.assessments {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 125px);
  flex-direction: column;
  gap: 3rem;

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
}

.assessment {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  min-height: calc(100vh - 125px);

  &_card {
    justify-content: space-between;
    align-items: center;
  }

  &_content {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &_title {
    margin: 0;
  }

  &_date {
    color: #555;
    font-weight: 500;
  }

  &_button {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_row {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;

    &.alignEnd {
      justify-content: flex-end;
    }
  }

  &_col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  &_label {
    font-weight: 500;
  }

  &_error {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 125px);
    align-items: center;
    justify-content: center;

    .error_content {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-direction: column;

      h1 {
        margin-bottom: 2rem;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 3rem;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding-bottom: 2rem;
}

.entries {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 0.5rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &_container {
    gap: 2rem;
  }

  &_content {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &_title {
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  }

  &_date {
    color: #555;
    font-weight: 500;
  }

  &_button {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.column {
  flex-direction: column;

  & > * {
    width: 100%;
  }
}
