.page_layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .layout_container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
  }

  .page_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-top: 110px;

    @media screen and (min-width: 768px) {
      margin-top: 0px;
    }
  }

  .page_content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      padding: 3rem 2rem 12rem 2rem;
    }

    @media screen and (min-width: 768px) {
      & > * {
        padding: 2rem 3rem;
      }
    }
  }

  .page_sidebar {
    display: flex;
    align-items: center;
    height: 100%;
    overflow-y: auto;
  }

  .footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copywrite {
      font-size: 1.6rem;
      opacity: 0.7;
    }
  }
}

.page_version {
  // position: absolute;
  // bottom: 0;
  z-index: 1111;
  padding: 0 1rem;
}
