.assessmentItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  &.content {
    gap: 1rem;
  }

  &_description {
    font-size: 1.6rem;
  }

  &.assessment {
    gap: 2rem;
  }

  &_row {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;

    &.alignEnd {
      justify-content: flex-end;
      padding: 2.5rem;
    }
  }

  &_col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.assessment_order {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 0 2rem;

  .question {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .bullet {
    font-size: 1rem;
    margin-top: 1px;
  }

  .title {
    font-size: 1.6rem;
  }

  .type_content {
    width: 100%;
  }
}
