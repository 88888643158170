.accordion {
  &_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }

  &_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &_header,
  &_content {
    display: flex;
    width: 100%;
    padding: 2rem;
    position: relative;
  }

  &_header {
    border-radius: 8px;
    cursor: pointer;

    &.active {
      border-radius: 8px 8px 0 0;
    }
  }

  &_icon {
    position: absolute;
    right: 12px;
    top: 0;
    margin: auto 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 20px;
      width: 20px;
    }

    transition: transform 0.3s ease;

    &.active {
      transform: rotate(90deg);
    }
  }

  &_content {
    background: #292a2b0d;
    box-shadow: inset 0px -1px 1px rgba(20, 16, 0, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }

  &_label {
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
    color: var(--foreground);
  }
}
