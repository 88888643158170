.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 366px;
  gap: 2rem;
  overflow-y: auto;
  position: relative;
  transition: all 200ms ease-in-out;

  @media screen and (max-width: 767px) {
    display: none;
  }
  h1 {
    color: #fff;
    line-height: 3rem;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 1rem;
  }
  .collapseButton {
    padding: 2rem 0.5rem 2rem 0;
  }

  &.collapse {
    width: 120px;
  }

  &_contact {
    background: linear-gradient(30deg, #36505b, #00998c);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0 10px 10px 0;

    h2,
    p {
      color: #fff;
    }

    h2 {
      font-size: 2rem;
      line-height: 2.1rem;
      margin-bottom: -5px;
    }

    p {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  .menu_container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-right: 1px solid #ccc;
  }

  &_logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-bottom: 3rem;
  }

  .logo_image {
    width: 100%;
    object-fit: contain;

    &.collapse_logo {
      width: 30px;
      height: 30px;
    }
  }

  &_menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    overflow: hidden;

    &.collapse_menu {
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
  }

  .menu_icon {
    box-shadow: 0 0 10px rgb(0 0 0 / 12%);
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00998c;
  }

  .contact_icon {
    box-shadow: 0 0 10px rgb(0 0 0 / 12%);
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    margin-right: auto;
  }

  .menu_item {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--foreground);
    line-height: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #fff;
    border-radius: 6px;
    text-transform: capitalize;
    transition: background-color 0.3s ease;

    &.collapse_item {
      padding: 0;
      width: unset;
    }

    &.active,
    &:hover {
      color: #fff;
      background: linear-gradient(45deg, #fa9f57, #ea6442);
      border: none;

      svg {
        color: #fff;
      }

      .menu_icon {
        background: transparent;
        box-shadow: none;
        color: #fff;
      }
    }
  }

  .contact_us {
    width: 100%;
    display: block;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #00998c;
    background-color: #fff;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    padding: 1rem;
  }
}
