@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  overflow: hidden;
}

p {
  font-size: 1.6rem;
  color: #222;
  letter-spacing: 0.025rem;
  margin-bottom: 1rem;
  line-height: 2.2rem;
}

a {
  text-decoration: none;
}

.ql-editor {
  min-height: 12em;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 3.3rem;
  line-height: 3rem;
  color: #555555;
  letter-spacing: 0.025rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #555555;
  letter-spacing: 0.1rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #555555;
  letter-spacing: 0.025rem;
  margin-bottom: 0.6rem;
}

.version-number {
  font-size: 1.2rem;
  font-style: italic;
  color: #999;
}

@media screen and (min-width: 768px) {
  .MuiToolbar-root {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  header {
    border-bottom: 1px solid #ccc;
  }
  .mobileHeader {
    border: 0;
  }
}
