.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 125px);
  flex-direction: column;

  &_content {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    h1 {
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 3rem;
    }
  }
}
