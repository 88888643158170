.label {
  font-size: 1.6rem;
  font-weight: 500;
}

p.MuiTypography-root,
.MuiInputBase-input::placeholder {
  font-size: 1.6rem;
}

.MuiFormControlLabel-label {
  width: 100%;
}

.pdf_wrapper > div {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  max-width: 612px !important;
  max-height: 612px !important;
}

.pdf_wrapper canvas {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  max-width: 612px !important;
  max-height: 612px !important;
}

/** header styles **/
header {
  padding-top: 8px;
  background: transparent !important;
}

.smallLogo {
  position: absolute;
  top: 8px;
  background: #fff;
  left: 28px;
  padding: 28px 12px;
}

.logoutButton {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
}

.logoutButton:hover {
  color: #00998c;
}

.breadCrumb {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #212121;
  padding: 40px 20px 40px 0;
  line-height: 1;
}

.navStyles {
  margin: 40px 20px 0 30px;
  text-align: left;
  border-right: 2px solid #edebeb;
  padding-right: 50px;
}

.navStyles nav {
  border-top: 1px solid #d8d8d8;
}

/** header mobile styles **/

@media screen and (max-width: 767px) {
  header {
    display: none;
  }

  .MuiToolbar-root button {
    margin-right: 0 !important;
  }

  body .css-1ys0cin-MuiToolbar-root {
    padding: 10px 20px !important;
    margin-top: -10px;
  }

  .accordionDetails .accordionStats strong {
    width: 100% !important;
    display: block !important;
    padding: 0;
    margin-bottom: 10px;
  }

  .entries {
    margin-top: 12px !important;
  }

  .accordionBtns .buttonLink {
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 0 !important;
    display: inline-flex !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }

  .impersonation button {
    position: relative !important;
  }
  .impersonation p {
    line-height: 1.2 !important;
  }

  h2.accordionTitle {
    font-size: 19px !important;
  }

  .tableWrap {
    width: 100%;
    overflow-x: scroll;
  }

  .tableWrap table {
    min-width: 790px;
  }

  .smallLogo {
    display: block !important;
  }

  .breadCrumb {
    padding: 15px 20px;
  }

  header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed.css-hg5tx5-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
  }

  .firstCol {
    width: 36% !important;
  }
}

@media screen and (min-width: 768px) {
  header.mobileHeader {
    display: none !important;
  }

  .language-select-root svg {
    fill: #00998c !important;
  }

  .language-select-root button {
    color: #212121 !important;
  }

  .logoutButton {
    color: #212121;
    font-size: 15px !important;
    line-height: 1 !important;
    padding: 0 !important;
  }

  .language-select-root button {
    color: #212121 !important;
    padding: 0 30px;
    font-size: 15px;
    text-transform: capitalize !important;
    line-height: 1 !important;
  }

  .viewToggle span.MuiFormControlLabel-label {
    color: #212121 !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 1 !important;
  }

  .languageList ul {
    background: #eeeeee75;
    border-radius: 10px;
    margin-top: 10px;
  }

  .languageList div {
    border-top: 1px dashed #d1d1d1;
  }
}

/** mobile drawer styling **/

.mobileDrawer .MuiDrawer-paper {
  width: 100%;
  background: linear-gradient(30deg, #00998c, #36505b);
}

.mobileDrawer .navStyles {
  border: 0;
  padding: 0;
  margin: 30px;
}

.mobileDrawer .navStyles nav {
  border: 0;
  padding: 0;
}

.mobileDrawer .navStyles a,
.mobileDrawer .navStyles span,
.mobileDrawer .navStyles button {
  color: #fff !important;
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 17px;
  font-weight: 500;
  padding: 5px 0;
}

.mobileDrawer .navStyles nav svg {
  background: #fff !important;
  border-radius: 5px !important;
  font-size: 39px !important;
  color: #157e7a !important;
}

.mobileDrawer .navStyles nav a:hover svg,
.mobileDrawer .navStyles nav a:focus svg {
  fill: #fff !important;
  background: transparent !important;
  font-size: 39px !important;
}

.mobileDrawer .logoutButton svg {
  fill: #fff !important;
}

ul.userToolbar {
  border-top: 1px solid #ffffff52;
  border-bottom: 1px solid #ffffff52;
  padding-top: 20px !important;
  padding-bottom: 35px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 10px 35px;
  margin-bottom: 2rem;
}

ul.userToolbar span {
  display: flex;
  align-items: center;
}

ul.userToolbar span img {
  padding: 11px 11px 11px 8px;
  width: 60px;
}

ul.userToolbar li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.user {
  margin-bottom: -6px;
}

ul.userToolbar a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 6px;
}

ul.userToolbar a svg {
  fill: #fff;
  margin-right: 8px;
}

.navCta p,
.navCta h2 {
  color: #fff;
}

.navCta p {
  margin-top: 0;
}

.navCta h2 {
  line-height: 1;
  margin-bottom: 15px;
  display: block;
}

.mobileDrawer .navCta a {
  background: #fff;
  padding: 10px;
  color: #222225 !important;
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 7px;
}

header.mobileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

body .css-1p63slh-MuiToolbar-root {
  padding: 20px 30px !important;
}

@media screen and (min-width: 768px) {
  .navCta {
    margin: 30px 20px 0 32px;
    background: linear-gradient(30deg, #00998c, #36505b);
    text-align: left;
    padding: 10px 30px;
    border-radius: 10px;
  }

  .navCta a {
    background: #fff;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 600;
    padding: 10px 10px;
    margin-bottom: 27px;
  }
}

/** custom link styling **/

.buttonLink {
  background: linear-gradient(30deg, #00998c, #36505b);
  padding: 6px 15px !important;
  color: #fff !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  font-size: 15px;
  cursor: pointer;
  line-height: 1.3 !important;
  height: 36px;
  font-size: 1.4rem !important;
  letter-spacing: 0px;
}

.buttonLink:hover {
  background: linear-gradient(30deg, #00998c, #36505b);
}

.Mui-selected .give-monthly-label,
.Mui-selected .give-once-label {
  background: linear-gradient(30deg, #fba358, #eb6843) !important;
}
.give-monthly-label,
.give-once-label {
  background: #c6c6c6 !important;
  padding: 8px 16px;
  border-radius: 4px;
  color: #fff;
  font-size: 22px;
  margin: 0 -20px;
}
.give-monthly-label {
  margin-left: -30px;
}

@media screen and (max-width: 767px) {
  .give-monthly-label,
  .give-once-label {
    font-size: 20px;
  }
}

.tabs-wrapper span.MuiTabs-indicator {
  display: none !important;
}

.buttonLink.outline {
  background: transparent;
  border: 2px solid #36505b;
  color: #36505b !important;
  transition: 0.3s all;
}

.buttonLink.outline:hover {
  background: #36505b;
  color: #fff !important;
}

.buttonLink.outline.red {
  color: #e85e40 !important;
  border-color: #e85e40;
  transition: 0.3s all;
  padding: 6px 15px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  letter-spacing: 0px;
}
.accordionBtns .buttonLink.outline.red {
  margin-top: 10px !important;
}
@media screen and (min-width: 768px) {
  .accordionBtns .buttonLink.outline.red {
    margin-top: 0 !important;
  }
}
.buttonLink.outline.red:hover {
  background: #e85e40;
  border: 2px solid #e85e40 !important;
  color: #ffffff !important;
}

.buttonLink.gray {
  color: #fff !important;
  background: #36515c !important;
}

.buttonLink.gray:hover {
  background-color: #00998c !important;
}

.buttonLink.transparent {
  background: transparent;
  color: #00998c !important;
  display: block;
  padding: 10px 0;
}

.buttonLink.transparent:hover {
  color: #36515c !important;
}

.language-select-root button svg {
  fill: #fff;
  margin-right: 9px;
  font-size: 20px;
}

.languageList ul {
  padding: 0;
}

.languageList .MuiListSubheader-root {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: -1px;
  background: transparent;
  text-transform: uppercase;
}

body .searchBar {
  // box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #ccc !important;
}

.searchBar input::placeholder {
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.filterBar {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  height: 43px !important;
}

.filterBar label {
  font-size: 15px;
  font-weight: 600;
  color: #00998c;
  top: 0px;
}

.filterBar #mui-component-select-filter {
  border-radius: 14px !important;
  padding: 15px 10px !important;
}

/** ACCORDION STYLES **/
.MuiAccordion-root:first-child .accordionToggle {
  border-top: 0 !important;
}

.accordionToggle {
  padding: 27px 0 !important;
  background: transparent !important;
}

.accordionToggle {
  border-top: 1px solid #e0e0e0 !important;
}

.accordionToggle div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
}

.single div {
  margin: 0 !important;
}

.accordionTitle {
  width: 66%;
  text-align: left;
  color: #212121;
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
  padding: 0 1rem;
}

@media screen and (max-width: 1510px) {
  .accordionTitle {
    padding-left: 20px;
  }
}

.accordionTitle svg {
  fill: #000;
  position: relative;
  top: 4px;
}

.accordionImage {
  width: 98px;
  height: 98px;
  min-width: 98px;
  background: #eee;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
}

@media screen and (min-width: 1366px) {
  .accordionImage {
    min-width: 98px;
  }

  .firstCol {
    width: 68%;
  }
}

.MuiAccordionSummary-root {
  margin-bottom: 0 !important;
}

.accordionDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0 !important;
}

.accordionDetails .accordionTitle {
  width: 44%;
}

.single .accordionTitle {
  width: 44%;
}

.accordionStats {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  color: #00998c;
}

.accordionStats span {
  display: inline-block;
  padding: 5px 14px;
  font-size: 1.4rem;
}

.accordionStats strong {
  color: #212121;
  font-size: 1.5rem;
  position: relative;
  padding-left: 4px;
}

.single .accordionStats {
  display: block !important;
}

.single .accordionStats span {
  display: block !important;
  padding-bottom: 0;
  padding-top: 0;
  text-align: right;
  line-height: 1;
  margin-bottom: 11px;
  font-size: 1.4rem;
}

/** accordion responsive styles **/

@media screen and (max-width: 1024px) {
  .accordionToggle {
    display: block !important;
  }

  .accordionToggle div {
    display: block;
  }

  .accordionImage {
    display: inline-block !important;
    width: 20% !important;
    float: left;
  }

  .accordionTitle {
    display: inline-block !important;
    width: 71% !important;
    margin-top: 0 !important;
    line-height: 1.2 !important;
  }

  .accordionDetails {
    padding: 20px 0 !important;
  }

  .accordionDetails .accordionTitle {
    padding-left: 0 !important;
    font-size: 18px !important;
  }

  .accordionStats {
    text-align: left;
    margin-left: 20px;
  }

  .accordionStats span {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1;
  }

  .accordionBtns {
    text-align: right;
  }

  .accordionDetails .accordionBtns a {
    margin-right: 0 !important;
    margin-top: 5px !important;
    padding: 3px 10px !important;
    font-size: 14px;
  }

  .single .accordionStats {
    margin-bottom: 20px !important;
  }

  body .css-9zgbfc-MuiToolbar-root {
    padding: 0 20px !important;
  }

  .css-13wyc20-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0 0 4px rgb(0 0 0 / 30%) !important;
  }
}

@media (min-width: 768px) and (max-width: 1700px) {
  .accordionBtns {
    display: flex;
  }

  .css-44mweq-MuiAccordionDetails-root {
    padding-left: 0 !important;
  }

  .accordionStats span {
    text-align: right;
  }

  .accordionDetails .accordionStats {
    display: flex;
    line-height: 1.1;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .accordionStats strong {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  header {
    padding-top: 0 !important;
  }

  .userToolbar {
    margin-bottom: 20px;
    display: block !important;
  }
  .userToolbar div {
    width: 100% !important;
    max-width: 100%;
  }

  .accordionStats span {
    text-align: right;
    margin-bottom: 10px !important;
  }

  .single .accordionStats span {
    padding: 0 10px !important;
  }
  .mobileToolbar {
    display: none !important;
  }

  .single .accordionStats {
    display: flex !important;
    padding: 0 0 0 20px !important;
  }

  .accordionTitle {
    width: 80% !important;
    text-align: right !important;
  }

  .accordionDetails {
    display: block !important;
  }
  .accordionDetails .accordionTitle {
    width: 100% !important;
    text-align: right !important;
  }

  .accordionDetails .accordionStats {
    width: 100% !important;
    display: block !important;
    text-align: right;
  }
  .accordionDetails .accordionStats span {
    padding: 0 20px !important;
  }

  .accordionDetails .accordionBtns a {
    margin-left: 10px;
  }

  .accordionDetails .accordionBtns {
    width: 100%;
    text-align: right !important;
    display: block;
    margin-top: 10px;
  }

  .tableWrap {
    width: 100%;
    overflow-x: scroll;
  }

  .hiddenTablet {
    display: none;
  }

  .tableWrap table {
    min-width: 790px;
  }

  .navStyles {
    padding-right: 0px !important;
    margin: 40px 20px 0 20px !important;
  }

  .navStyles img {
    padding-bottom: 0 !important;
  }

  .css-qlmcuo-MuiPaper-root-MuiAppBar-root {
    margin-left: 290px !important;
    width: calc(100% - 289px) !important;
  }

  .css-1p63slh-MuiToolbar-root {
    min-height: 62px;
  }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-9zgbfc-MuiToolbar-root {
    justify-content: flex-end;
  }

  body .css-1lj6pdy-MuiContainer-root {
    padding: 10px 10px 10px 0 !important;
  }

  .breadCrumb {
    display: none !important;
  }

  .navStyles a:hover {
    border-radius: 9px 0 0 9px !important;
  }

  .navCta {
    margin: 30px 20px 0 20px !important;
  }

  .css-h0upic-MuiDrawer-docked .MuiDrawer-paper {
    width: 290px !important;
  }

  .css-1jcfdrd {
    width: 290px !important;
  }

  .css-13wyc20-MuiPaper-root-MuiAppBar-root {
    width: calc(100% - 290px) !important;
    width: 290px !important;
  }
}

/** table styling **/
.productsTable {
  margin-top: 21px;
}

.productsTable th {
  font-weight: 700;
  color: #00998c;
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
  letter-spacing: 0.7px;
  padding: 20px 0;
}

.productsTable td {
  font-size: 15px;
  padding: 25px 0px;
}

.productsTable tr td {
  border: 0 !important;
}

.firstCol {
  width: 38%;
}

/** base form styling **/
label {
  font-weight: 600;
  font-size: 16px;
}

.form-group {
  margin: 60px 0px;
}

.selectBox {
  font-weight: 600;
  color: #36505b;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border: 0;
}

/** impersonation notice **/
.impersonation {
  background: transparent linear-gradient(91deg, #fca659 0%, #e85e40 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  z-index: 9999;
  left: 0;
  width: 100%;
  padding: 10px;
}
.impersonation p {
  color: #212121;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.impersonation button {
  position: absolute;
  right: 0;
  top: 4px;
  color: #212121;
  padding-right: 19px;
  font-weight: 600;
}
.impersonation button svg {
  margin-right: 0;
  padding-right: 5px;
}

.impersonation svg {
  margin-right: 6px;
  position: relative;
  top: 0px;
  fill: #212121;
}

/** colorPicker styling **/

.colorPicker fieldset {
  border: 0 !important;
}

.colorPicker input {
  padding: 0 !important;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.colorPicker input[type='color'] {
  width: 53px;
  height: 53px;
  margin: -4px -4px -4px 0;
  border: 0 !important;
  overflow: hidden;
}

.colorPicker span {
  display: block;
  line-height: 1;
  margin-top: 10px;
}

.colorPicker .pickerWrap {
  display: flex;
}

.colorPicker span {
  font-size: 1.3rem;
  font-weight: 500;
}

.colorPicker .pickerText {
  padding-left: 10px !important;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  border-radius: 0 5px 5px 0 !important;
}

.colorPicker .pickerText:focus,
.colorPicker .pickerText:focus-visible {
  border: 2px solid #00998c !important;
  outline: 0;
}

/** circular progress bar **/
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
  width: 100%;
  /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #1c988b;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #fff;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #36505b;
  font-size: 30px;
  dominant-baseline: middle;
  text-anchor: middle;
  font-weight: 700;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #36505b;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

/** accordion expand icon **/
.Mui-expanded h2 svg {
  transform: rotate(180deg);
}

.MuiAccordionSummary-expandIconWrapper {
  margin-left: 10px;
}

/** MUI styling overrides - delete if conflicting **/
.MuiTableCell-body,
.MuiInputBase-input,
label {
  font-family: 'Montserrat', sans-serif !important;
}
.MuiToolbar-root button {
  cursor: pointer;
  transition: 0.3s all;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.css-1l6kr3n-MuiTypography-root {
  font-weight: 600 !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-1ow8ej8-MuiToolbar-root h1,
header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionAbsolute.css-w44h7q-MuiPaper-root-MuiAppBar-root
  .breadCrumb {
  margin-left: 148px;
}

.MuiPaper-root.css-1b7hko-MuiPaper-root-MuiDrawer-paper img.drawerLogo {
  opacity: 1;
  transition: 0.6s all;
}

.MuiDrawer-root.MuiDrawer-docked.css-xc4j8j-MuiDrawer-docked span,
.MuiDrawer-root.MuiDrawer-docked.css-xc4j8j-MuiDrawer-docked img.drawerLogo {
  opacity: 0;
  transition: 0.3s all;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.css-1oeiycf-MuiButtonBase-root-MuiListItemButton-root {
  padding: 11px;
}

.MuiDrawer-root.MuiDrawer-docked.css-xc4j8j-MuiDrawer-docked .collapseIcon {
  text-align: center !important;
  display: flex !important;
  width: auto;
  align-items: center;
  justify-content: center !important;
  transform: rotate(181deg);
}

.MuiDrawer-root.MuiDrawer-docked.css-xc4j8j-MuiDrawer-docked .collapseIcon span {
  display: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionAbsolute.css-w44h7q-MuiPaper-root-MuiAppBar-root
  .breadCrumb {
  padding-left: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-17xxhqv-MuiPaper-root-MuiAccordion-root {
  margin: 0 !important;
}

.separator svg {
  font-size: 9px;
  margin: 0 10px;
  fill: #f99554;
}

.collapseIcon {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 99999 !important;
}

.fixedNav {
  background: transparent linear-gradient(93deg, #00998c 0%, #36505b 100%) 0% 0% no-repeat padding-box;
  border-radius: 9px 9px 0px 0px;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.screenreader {
  position: absolute;
  left: -9999px;
}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// Video Quill React Override
.ql-tooltip {
  position: absolute;
  left: 10px !important;
}

iframe.ql-video {
  width: 100%;
  height: 360px;
}

.ql-editor {
  min-height: 70px;
  max-height: 380px;
}

.MuiTextField-root {
  font-size: 1.6rem;
  font-family: inherit;
}

.swiper-slide-thumb-active {
  opacity: 1 !important;
}
