.logo-top {
  text-align: left;
  padding: 40px 40px 0;
  position: absolute;
  top: 0;
}

.loginBg {
  position: relative;
}
.loginBg:before {
  content: '';
  background: linear-gradient(to right bottom, transparent, #36505b);
  opacity: 0.5;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
}

.signin_form {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
}

.signin_options {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.signin_buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  padding: 1rem 0;
}

.signin_providers {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.label_textfield {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0;
}

.label_textfield span {
  font-weight: 600;
}

.button_google {
  box-shadow:
    0px 0px 0.7px rgba(0, 0, 0, 0.25),
    0px 0px 1.9px rgba(0, 0, 0, 0.163),
    0px 0px 4.5px rgba(0, 0, 0, 0.125),
    0px 0px 15px rgba(0, 0, 0, 0.087);
}

.button_google svg {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

.signin_button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  position: absolute;
  width: 100%;
  bottom: -230px;
}

@media (max-width: 900px) {
  .copyright {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0 1.5rem;

    position: fixed;
    bottom: 10px;
  }
}
